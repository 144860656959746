body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main-content {
  flex-grow: 1;
}

.main-content {
  background-image: url("/public/filf_large.jpg");
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}

.centered-text {
  background-color: rgba(
    0,
    0,
    0,
    0.9
  ); /* Adjust the alpha value to 0.7 for less transparency */
  padding: 4rem; /* Increased padding to make the container larger */
  border-radius: 8px;
}

.centered-text h1 {
  font-size: 3rem; /* Increased font size for the heading */
}

.centered-text p {
  font-size: 1.5rem; /* Increased font size for the paragraph */
}
